.w-form-formradioinput--inputType-custom {
  border-top-width: 1px;
  border-bottom-width: 1px;
  border-left-width: 1px;
  border-right-width: 1px;
  border-top-color: #ccc;
  border-bottom-color: #ccc;
  border-left-color: #ccc;
  border-right-color: #ccc;
  border-top-style: solid;
  border-bottom-style: solid;
  border-left-style: solid;
  border-right-style: solid;
  width: 12px;
  height: 12px;
  border-bottom-left-radius: 50%;
  border-bottom-right-radius: 50%;
  border-top-left-radius: 50%;
  border-top-right-radius: 50%;
}

.w-form-formradioinput--inputType-custom.w--redirected-focus {
  box-shadow: 0px 0px 3px 1px #3898ec;
}

.w-form-formradioinput--inputType-custom.w--redirected-checked {
  border-top-width: 4px;
  border-bottom-width: 4px;
  border-left-width: 4px;
  border-right-width: 4px;
  border-top-color: #3898ec;
  border-bottom-color: #3898ec;
  border-left-color: #3898ec;
  border-right-color: #3898ec;
}

.w-checkbox {
  display: block;
  margin-bottom: 5px;
  padding-left: 20px;
}

.w-checkbox::before {
  content: ' ';
  display: table;
  -ms-grid-column-span: 1;
  grid-column-end: 2;
  -ms-grid-column: 1;
  grid-column-start: 1;
  -ms-grid-row-span: 1;
  grid-row-end: 2;
  -ms-grid-row: 1;
  grid-row-start: 1;
}

.w-checkbox::after {
  content: ' ';
  display: table;
  -ms-grid-column-span: 1;
  grid-column-end: 2;
  -ms-grid-column: 1;
  grid-column-start: 1;
  -ms-grid-row-span: 1;
  grid-row-end: 2;
  -ms-grid-row: 1;
  grid-row-start: 1;
  clear: both;
}

.w-checkbox-input {
  float: left;
  margin-bottom: 0px;
  margin-left: -20px;
  margin-right: 0px;
  margin-top: 4px;
  line-height: normal;
}

.w-checkbox-input--inputType-custom {
  border-top-width: 1px;
  border-bottom-width: 1px;
  border-left-width: 1px;
  border-right-width: 1px;
  border-top-color: #ccc;
  border-bottom-color: #ccc;
  border-left-color: #ccc;
  border-right-color: #ccc;
  border-top-style: solid;
  border-bottom-style: solid;
  border-left-style: solid;
  border-right-style: solid;
  width: 12px;
  height: 12px;
  border-bottom-left-radius: 2px;
  border-bottom-right-radius: 2px;
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
}

.w-checkbox-input--inputType-custom.w--redirected-checked {
  background-color: #3898ec;
  border-top-color: #3898ec;
  border-bottom-color: #3898ec;
  border-left-color: #3898ec;
  border-right-color: #3898ec;
  background-image: url('https://d3e54v103j8qbb.cloudfront.net/static/custom-checkbox-checkmark.589d534424.svg');
  background-position: 50% 50%;
  background-size: cover;
  background-repeat: no-repeat;
}

.w-checkbox-input--inputType-custom.w--redirected-focus {
  box-shadow: 0px 0px 3px 1px #3898ec;
}

h1 {
  margin-top: 20px;
  margin-bottom: 10px;
  font-size: 38px;
  line-height: 44px;
  font-weight: bold;
}

h2 {
  margin-top: 0px;
  margin-bottom: 25px;
  font-family: futura-pt, sans-serif;
  color: #442c79;
  font-size: 32px;
  line-height: 36px;
  font-weight: 700;
}

h4 {
  margin-top: 0px;
  margin-bottom: 15px;
  font-family: futura-pt, sans-serif;
  color: #442c79;
  font-size: 20px;
  line-height: 25px;
  font-weight: 700;
}

h5 {
  margin-top: 10px;
  margin-bottom: 10px;
  color: #231f20;
  font-size: 18px;
  line-height: 22px;
  font-weight: 700;
}

p {
  margin-bottom: 0px;
  font-family: 'Open Sans', sans-serif;
  color: #231f20;
  font-size: 16px;
  line-height: 26px;
}

a {
  text-decoration: underline;
}

li {
  margin-bottom: 10px;
  color: #231f20;
}

.registration-flex-wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 120vh;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
}

.logo-sidebar {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 25%;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-flex: 0;
  -webkit-flex: 0 auto;
  -ms-flex: 0 auto;
  flex: 0 auto;
  background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(118, 185, 0, 0.4)), to(rgba(118, 185, 0, 0.4))), url('../images/apples.jpg');
  background-image: linear-gradient(180deg, rgba(118, 185, 0, 0.4), rgba(118, 185, 0, 0.4)), url('../images/apples.jpg');
  background-position: 0px 0px, 50% 50%;
  background-size: auto, cover;
  background-repeat: repeat, no-repeat;
}

.login-container {
  width: 75%;
}

.login-form-div {
  width: 1000px;
  max-width: 90%;
  margin-top: 40px;
  margin-right: auto;
  margin-left: auto;
}

.tabs-menu {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}

.login-tab-link {
  margin-left: 20px;
  padding-right: 0px;
  padding-left: 0px;
  border-bottom: 3px solid transparent;
  background-color: transparent;
  -webkit-transition: border-color 500ms ease;
  transition: border-color 500ms ease;
  font-family: futura-pt-bold, sans-serif;
  color: #76b900;
  font-size: 18px;
}

.login-tab-link:hover {
  border-bottom-color: #76b900;
}

.login-tab-link.w--current {
  border-bottom: 3px solid #76b900;
  background-color: transparent;
}

.login-tabs-content {
  margin-top: 30px;
}

.purple-headline {
  font-family: futura-pt, sans-serif;
  color: #442c79;
}

.purple-headline.half-width {
  width: 500px;
  max-width: 70%;
}

.purple-headline.boxed-in {
  margin-top: 30px;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 10px;
  background-color: #f5f3f7;
}

.login-form {
  margin-top: 50px;
  margin-bottom: 50px;
}

.field-combo {
  display: inline-block;
  width: 49%;
  max-width: 100%;
  clear: left;
}

.field-combo.stack {
  display: block;
}

.field-combo.thirds {
  width: 32%;
}

.field-label {
  margin-top: 25px;
  font-family: futura-pt-bold, sans-serif;
  color: #231f20;
  font-size: 16px;
  line-height: 26px;
}

.field-combo-wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 0px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: end;
  -webkit-align-items: flex-end;
  -ms-flex-align: end;
  align-items: flex-end;
}

.field-combo-wrapper.radio-wrapper {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.text-field {
  height: 55px;
  margin-bottom: 20px;
  border-style: solid;
  border-width: 2px;
  border-color: hsla(0, 0%, 77.3%, 0.3);
  background-color: #fff;
  -webkit-transition: border-color 500ms ease;
  transition: border-color 500ms ease;
  font-family: 'Open Sans', sans-serif;
  font-size: 16px;
  line-height: 26px;
}

.text-field:hover {
  border-color: #76b900;
}

.text-field:active {
  border-color: #76b900;
}

.text-field:focus {
  border-color: #76b900;
}

.text-field::-webkit-input-placeholder {
  color: #c5c5c5;
}

.text-field:-ms-input-placeholder {
  color: #c5c5c5;
}

.text-field::-ms-input-placeholder {
  color: #c5c5c5;
}

.text-field::placeholder {
  color: #c5c5c5;
}

.btn-fill-p {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 10px 25px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-style: solid;
  border-width: 2px;
  border-color: #442c79;
  border-radius: 30px;
  background-color: transparent;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
  font-family: futura-pt, sans-serif;
  color: #442c79;
  font-size: 16px;
  line-height: 26px;
  font-weight: 700;
  text-align: center;
}

.btn-fill-p:hover {
  background-color: #442c79;
  color: #fff;
}

.btn-fill-p.top-pad-ten {
  margin-top: 10px;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.btn-fill-p.top-pad-ten:hover {
  background-color: #442c79;
  color: #fff;
}

.bold-text {
  color: #442c79;
}

.text-link {
  margin-right: 15px;
  margin-left: 15px;
  font-family: futura-pt, sans-serif;
  color: #000;
  font-size: 16px;
}

.password-guide {
  position: relative;
  margin-bottom: 10px;
  padding: 10px 10px 10px 15px;
  background-color: #f5f3f7;
  font-family: futura-pt, sans-serif;
  font-size: 16px;
  line-height: 20px;
}

.reg-background {
  background-color: #fff;
  font-family: futura-pt, sans-serif;
  font-size: 16px;
  line-height: 26px;
}

.registration-nav {
  background-color: #fff;
  box-shadow: 1px 1px 3px 0 #c5c5c5;
}

.reg-wrapper-div {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 1000px;
  max-width: 90%;
  margin-right: auto;
  margin-left: auto;
  padding-top: 20px;
  padding-bottom: 20px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.progress-bar {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 300px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  font-family: futura-pt-bold, sans-serif;
  color: #4a4a4a;
  font-size: 16px;
}

.progress-bubble {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 40px;
  height: 40px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-style: solid;
  border-width: 1px;
  border-color: #76b900;
  border-radius: 20px;
  background-color: #76b900;
  font-family: futura-pt, sans-serif;
  color: #fff;
  font-weight: 700;
  text-decoration: none;
}

.progress-bubble.inactive {
  border-width: 2px;
  border-color: hsla(0, 0%, 77.3%, 0.3);
  background-color: #fff;
  color: #76b900;
}

.bubble-div {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.term-text {
  margin-left: 5px;
  font-family: 'Open Sans', sans-serif;
  color: #4a4a4a;
  font-size: 14px;
  line-height: 20px;
}

.radio-inline {
  display: inline-block;
  margin-right: 30px;
  padding-left: 0px;
  font-family: 'Open Sans', sans-serif;
  font-size: 14px;
}

.checkbox-inline {
  display: inline-block;
  margin-right: 20px;
  padding-left: 0px;
  font-family: 'Open Sans', sans-serif;
}

.checkbox-inline._16 {
  width: 16.66%;
  margin-right: 0px;
}

.checkbox-inline._7 {
  width: 7.14%;
  margin-right: 0px;
}

.team-block {
  margin-top: 30px;
}

.grey-headline {
  font-family: futura-pt-bold, sans-serif;
  color: #231f20;
}

.form-save {
  margin-right: 25px;
  padding-right: 5px;
  padding-left: 5px;
  border-bottom: 2px solid transparent;
  background-color: transparent;
  -webkit-transition: border-color 500ms ease;
  transition: border-color 500ms ease;
  font-family: futura-pt, sans-serif;
  color: #76b900;
  font-size: 16px;
  line-height: 26px;
  font-weight: 700;
}

.form-save:hover {
  border-bottom-width: 2px;
  border-bottom-color: #76b900;
}

.bg-particiants {
  margin-top: 30px;
}

.participant-block {
  margin-top: 30px;
  border-radius: 20px;
}

.btn-solid-p {
  border-style: solid;
  border-width: 3px;
  border-color: #442c79;
  border-radius: 30px;
  background-color: #442c79;
  font-family: futura-pt-bold, sans-serif;
  font-size: 16px;
  line-height: 20px;
}

.agreement-div {
  margin-top: 30px;
  margin-bottom: 30px;
  padding: 30px;
  border-style: solid;
  border-width: 2px;
  border-color: hsla(0, 0%, 77.3%, 0.3);
  border-radius: 0.5px;
  background-color: #fcfcfc;
}

.agreement-div.scroll {
  overflow: scroll;
  height: 600px;
}

.agreement-text {
  padding-left: 0px;
  font-family: 'Open Sans', sans-serif;
}

.agreement-list-item {
  margin-bottom: 15px;
  color: #231f20;
  font-size: 16px;
  line-height: 26px;
}

.agreement-list-item.sub {
  margin-left: 37px;
}

.black-headline {
  font-family: futura-pt-bold, sans-serif;
  color: #000;
}

.agreement-confirm {
  margin-bottom: 20px;
}

.btn-solid-green {
  border-style: solid;
  border-width: 3px;
  border-color: #76b900;
  border-radius: 30px;
  background-color: #76b900;
  font-family: futura-pt, sans-serif;
  color: #fff;
  font-size: 16px;
  font-weight: 700;
}

.display-none {
  display: none;
}

.radio-label {
  padding-left: 30px;
  background-image: url('../images/empty-radio.svg');
  background-position: 0% 50%;
  background-size: 20px 20px;
  background-repeat: no-repeat;
  font-size: 16px;
  line-height: 26px;
}

.radio-field {
  padding-left: 0px;
  font-family: futura-pt-bold, sans-serif;
}

.radio-field.inline-radio {
  display: inline-block;
  width: 150px;
  font-family: 'Open Sans', sans-serif;
}

.radio-field.inline-radio.longer {
  width: auto;
}

.radio-field.inline-radio {
  display: inline-block;
  margin-right: 20px;
  font-family: 'Open Sans', sans-serif;
}

.checkbox-label {
  padding-left: 30px;
  background-image: url('../images/empty-radio.svg');
  background-position: 0% 50%;
  background-size: 20px 20px;
  background-repeat: no-repeat;
  font-size: 16px;
  line-height: 26px;
  font-weight: 400;
}

.checkbox-label.agree {
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.utility-page-wrap {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100vw;
  height: 100vh;
  max-height: 100%;
  max-width: 100%;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.utility-page-content {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 260px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  text-align: center;
}

.utility-page-form {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
}

.field-validation {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  background-color: transparent;
  font-family: 'Open Sans', sans-serif;
  color: #4a4a4a;
}

.validate-icon {
  margin-right: 5px;
}

.form-error {
  background-color: #ffeff3;
  font-family: futura-pt, sans-serif;
  font-size: 16px;
}

.form-block {
  width: 620px;
  max-width: 90%;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.admin-login {
  padding-right: 5px;
  padding-left: 5px;
  border-bottom: 3px solid transparent;
  background-color: transparent;
  -webkit-transition: border-color 500ms ease;
  transition: border-color 500ms ease;
  font-family: futura-pt-bold, sans-serif;
  color: #76b900;
  font-size: 16px;
}

.admin-login:hover {
  border-bottom-color: #76b900;
}

.btn-solid-grey {
  padding: 10px 25px;
  border-style: solid;
  border-width: 3px;
  border-color: #c5c5c5;
  border-radius: 30px;
  background-color: #c5c5c5;
  font-family: futura-pt, sans-serif;
  font-size: 16px;
  font-weight: 700;
}

.table-data {
  display: inline-block;
  font-size: 16px;
  line-height: 26px;
}

.table-data.file-size {
  font-family: futura-pt-bold, sans-serif;
  color: #442c79;
  font-size: 16px;
}

.table-data.expiration {
  font-family: futura-pt-bold, sans-serif;
  color: #442c79;
}

.table-data.year {
  font-family: futura-pt-bold, sans-serif;
  color: #442c79;
  font-weight: 700;
}

.table-data.status {
  font-family: futura-pt-bold, sans-serif;
  color: #442c79;
  font-weight: 700;
}

.table-wrapper {
  display: block;
  margin-top: 30px;
  margin-bottom: 30px;
  border-top: 1px solid #eee;
}

.table-wrapper.applications {
  width: 80%;
  margin-top: 0px;
  border-top-style: none;
  border-top-width: 0px;
}

.crud-action-icon-wrapper {
  position: relative;
  padding-right: 5px;
  padding-left: 5px;
  opacity: 0.25;
  -webkit-transition-property: none;
  transition-property: none;
}

.crud-action-icon-wrapper:hover {
  opacity: 1;
}

.crud-action-icon-wrapper:active {
  -webkit-transform: translate(0px, 1px);
  -ms-transform: translate(0px, 1px);
  transform: translate(0px, 1px);
}

.crud-action-icon-wrapper.isci:focus {
  opacity: 1;
}

.table-col {
  display: block;
  width: 20%;
  min-width: 100px;
  padding-right: 10px;
  float: left;
  font-family: 'Open Sans', sans-serif;
  font-size: 13px;
}

.table-col.table-header {
  font-family: futura-pt-bold, sans-serif;
  color: #442c79;
  font-size: 14px;
}

.table-col.actions {
  position: relative;
  width: auto;
  padding-right: 0px;
  float: right;
  text-align: right;
}

.table-sort-icon {
  display: inline-block;
  padding-right: 5px;
  padding-left: 5px;
  opacity: 0.4;
}

.table-row {
  display: block;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 10px;
  border-bottom: 0.5px none #c5c5c5;
}

.table-row:hover {
  background-color: #fafafa;
}

.table-row.table-header {
  background-color: #f5f3f7;
  color: #333;
  font-weight: 700;
}

.table-row.document {
  padding-top: 10px;
  padding-right: 10px;
  border-bottom: 2px solid hsla(0, 0%, 77.3%, 0.3);
  color: #231f20;
}

.table-row.surveys {
  padding-top: 10px;
  padding-bottom: 10px;
  border-bottom: 2px solid hsla(0, 0%, 77.3%, 0.3);
}

.table-row.applications {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-top: 10px;
  padding-right: 10px;
  padding-bottom: 10px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-bottom: 2px solid hsla(0, 0%, 77.3%, 0.3);
}

.link-block {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  color: #000;
  text-decoration: none;
}

.link-block:hover {
  color: #4a4a4a;
}

.image {
  margin-right: 10px;
}

.image-2 {
  margin-top: 3px;
}

.user-settings {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

.link-block-3 {
  margin-right: 30px;
}

.image-3 {
  width: 20px;
  margin-left: 10px;
}

.wrapper {
  width: 1300px;
  max-width: 90%;
  margin-right: auto;
  margin-bottom: 75px;
  margin-left: auto;
  padding: 20px 0px;
  border-radius: 20px;
  background-color: #fff;
}

.wrapper.document {
  padding-bottom: 75px;
}

.main-header {
  color: #442c79;
  font-size: 18px;
  line-height: 28px;
}

.site-div {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 20px;
  padding-right: 15px;
  padding-left: 15px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: #f5f3f7;
}

.heading {
  margin-top: 0px;
  margin-bottom: 0px;
  color: #442c79;
  line-height: 28px;
}

.link-block-5 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  color: #442c79;
  font-size: 14px;
  font-weight: 700;
  text-decoration: none;
}

.image-4 {
  margin-left: 10px;
}

.month-header {
  margin-top: 0px;
  margin-bottom: 0px;
  font-family: futura-pt, sans-serif;
  color: #4a4a4a;
  font-size: 16px;
  line-height: 26px;
}

.text-block {
  font-family: 'Open Sans', sans-serif;
  font-size: 14px;
  line-height: 24px;
}

.text-span {
  color: #442c79;
  font-weight: 700;
}

.vertical-divider {
  width: 2px;
  height: 20px;
  margin-right: 10px;
  margin-left: 10px;
  background-color: hsla(0, 0%, 77.3%, 0.3);
}

.distribution-stats {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: -10px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.date-div {
  margin-bottom: 10px;
  padding: 10px 15px 25px;
  border-style: solid;
  border-width: 2px;
  border-color: hsla(0, 0%, 77.3%, 0.3);
  border-radius: 5px;
}

.date-div.active-details {
  padding-bottom: 0px;
  background-color: #f5f3f7;
}

.date-div.submitted-details {
  padding-bottom: 0px;
  background-color: #f1f1f1;
}

.date-div.selected {
  background-color: #f5f3f7;
}

.div-block-6 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.checkbox-label-2 {
  display: none;
}

.div-block-7 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.form-block-2 {
  margin-top: 15px;
  margin-left: 0px;
}

.checkbox-field {
  padding-left: 40px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.site-reports {
  margin-bottom: 45px;
}

.text-block-2 {
  margin-right: 10px;
  color: #4a4a4a;
  font-size: 13px;
  line-height: 23px;
  font-weight: 700;
  text-transform: uppercase;
}

.text-block-3 {
  color: #4a4a4a;
}

.link-block-6 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-bottom: 2px solid #76b900;
  color: #4a4a4a;
  text-decoration: none;
}

.image-5 {
  margin-left: 10px;
}

.date-from {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-right: 30px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  font-family: futura-pt, sans-serif;
}

.date-range {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

.date-range.date-pad {
  margin-bottom: 20px;
}

.export-reports-div {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 400px;
  padding-right: 15px;
  padding-left: 15px;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-style: solid;
  border-width: 2px;
  border-color: hsla(0, 0%, 77.3%, 0.3);
  border-radius: 5px;
  background-color: #f1f1f1;
}

.reports-selected {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 30px;
  margin-right: 10px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 5px;
  background-color: #442c79;
}

.reports-selected.none {
  background-color: #c5c5c5;
}

.text-block-4 {
  color: #fff;
  font-size: 13px;
  font-weight: 700;
}

.text-block-5 {
  color: #4a4a4a;
  text-transform: uppercase;
}

.form {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 15px;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.submit-button {
  margin-right: 20px;
  border-radius: 20px;
  background-color: #442c79;
  color: #fff;
  font-size: 13px;
  line-height: 13px;
  font-weight: 700;
  text-transform: uppercase;
}

.checkbox-label-3 {
  display: none;
}

.div-block-10 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.export-left {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

.export-right {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.date-export {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 20px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.school-sites-dropdown {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-top: 0px;
  padding-bottom: 10px;
  padding-left: 0px;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
}

.dashboard-nav {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 1000px;
  max-width: 90%;
  margin-right: auto;
  margin-left: auto;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.dropdown {
  z-index: 1;
}

.sites-dropdown {
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
  font-family: 'Open Sans', sans-serif;
  font-size: 14px;
  line-height: 24px;
}

.sites-dropdown:hover {
  background-color: rgba(118, 185, 0, 0.1);
  color: #76b900;
  font-weight: 600;
}

.sites-dropdown.w--current {
  color: #76b900;
  font-weight: 600;
}

.sites-dropdown.w--current:visited {
  color: #76b900;
  font-weight: 700;
}

.dropdown-list {
  margin-top: -15px;
  margin-left: 15px;
  border-radius: 5px;
  background-color: #fff;
}

.dropdown-list.w--open {
  border-radius: 5px;
  box-shadow: 0 0 20px 0 rgba(74, 74, 74, 0.2);
}

.section {
  padding-top: 25px;
  padding-right: 0px;
  padding-bottom: 25px;
  background-color: #442c79;
}

.reminder {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 1300px;
  max-width: 90%;
  margin-right: auto;
  margin-left: auto;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.heading-3 {
  margin-top: 0px;
  color: #fff;
  font-size: 16px;
  line-height: 26px;
}

.paragraph {
  margin-top: -5px;
  margin-bottom: 0px;
  font-family: futura-pt, sans-serif;
  color: #fff;
  font-size: 16px;
  line-height: 16px;
}

.dashboard-link {
  margin-right: 30px;
  margin-bottom: 10px;
  border-bottom: 2px solid transparent;
  -webkit-transition: border-color 500ms ease;
  transition: border-color 500ms ease;
  color: #231f20;
  font-size: 14px;
  font-weight: 700;
  text-decoration: none;
}

.dashboard-link:hover {
  border-bottom: 2px solid #76b900;
}

.dashboard-link.last {
  margin-right: 0px;
}

.dashboard-link.active {
  border-bottom: 2px solid #76b900;
}

.student-distribution-report {
  margin-top: 25px;
  padding: 20px;
  border-top: 2px solid hsla(0, 0%, 77.3%, 0.3);
  border-bottom: 2px solid hsla(0, 0%, 77.3%, 0.3);
  background-color: #fff;
}

.heading-4 {
  margin-top: 0px;
  margin-bottom: 0px;
  color: #231f20;
  font-size: 16px;
  line-height: 26px;
}

.paragraph-2 {
  margin-bottom: 20px;
  font-family: futura-pt, sans-serif;
  font-size: 16px;
  line-height: 26px;
}

.btn-green {
  padding: 10px 25px;
  border-style: solid;
  border-width: 2px;
  border-color: #76b900;
  border-radius: 30px;
  background-color: transparent;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
  font-family: futura-pt, sans-serif;
  color: #76b900;
  font-size: 16px;
  line-height: 26px;
  font-weight: 700;
}

.btn-green:hover {
  background-color: #76b900;
  color: #fff;
}

.btn-green.space {
  margin-right: 15px;
}

.btn-green.solid {
  border-width: 0px;
  background-color: #76b900;
  color: #fff;
}

.btn-green.solid:hover {
  background-color: #94c938;
}

.btn-green.solid.top-space {
  margin-top: 35px;
}

.section-2 {
  padding-bottom: 75px;
  color: #4a4a4a;
}

.modal-wrapper {
  position: fixed;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: 0%;
  z-index: 9999;
  display: none;
  height: 100%;
  margin-right: auto;
  margin-left: auto;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: rgba(74, 74, 74, 0.8);
}

.addstudent-popup {
  width: 700px;
  margin-right: auto;
  margin-left: auto;
  padding: 25px 25px 10px;
  border-radius: 5px;
  background-color: #fff;
  box-shadow: 0 0 20px 0 rgba(74, 74, 74, 0.5);
}

.heading-5 {
  margin-top: 0px;
  margin-bottom: 0px;
  color: #442c79;
  font-size: 18px;
  line-height: 28px;
}

.addstudent-field-label {
  margin-top: 10px;
  font-size: 16px;
  line-height: 26px;
  font-weight: 400;
}

.addstudent-field {
  border-style: solid;
  border-width: 1px;
  border-color: hsla(0, 0%, 60.8%, 0.5);
}

.addstudent-field.date {
  width: 122px;
}

.textarea {
  font-family: 'Open Sans', sans-serif;
  color: #4a4a4a;
}

.textarea::-webkit-input-placeholder {
  color: #c5c5c5;
}

.textarea:-ms-input-placeholder {
  color: #c5c5c5;
}

.textarea::-ms-input-placeholder {
  color: #c5c5c5;
}

.textarea::placeholder {
  color: #c5c5c5;
}

.form-button {
  border-radius: 25px;
  background-color: #76b900;
  color: #fff;
  line-height: 16px;
  font-weight: 700;
}

.form-button.cancel {
  margin-right: 15px;
  border-style: solid;
  border-width: 2px;
  border-color: #76b900;
  background-color: #fff;
  color: #76b900;
}

.form-buttons {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-top: 10px;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}

.student-name {
  width: 320px;
}

.id-number {
  width: 320px;
}

.div-block-12 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.addstudent-header {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.div-block-13 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.totals-divider {
  height: 2px;
  margin-top: 15px;
  margin-bottom: 15px;
  background-color: #c5c5c5;
}

.report-table {
  display: block;
  border-top: 1px none #eee;
}

.report-table-row {
  display: block;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 10px;
  border-bottom: 2px solid hsla(0, 0%, 77.3%, 0.3);
}

.report-table-row:hover {
  background-color: #fafafa;
}

.report-table-row.table-header {
  border-bottom-style: none;
  background-color: #fff;
  color: #333;
  font-weight: 700;
}

.report-table-row.last {
  border-bottom-style: none;
}

.report-table-col {
  display: block;
  width: auto;
  float: left;
  font-family: 'Open Sans', sans-serif;
  color: #000;
  font-size: 12px;
  line-height: 22px;
}

.report-table-col.id {
  width: 100px;
}

.report-table-col.table-header {
  color: #442c79;
  font-size: 14px;
}

.report-table-col.name {
  width: 150px;
}

.report-table-col.date {
  width: 70px;
}

.report-table-col.actions {
  position: relative;
  width: auto;
  padding-right: 0px;
  float: right;
  text-align: right;
}

.report-table-col.total-label {
  width: 26%;
}

.report-table-col.cell-1 {
  width: 10%;
  min-width: 60px;
  line-height: 14px;
}

.report-table-col.cell-2 {
  width: 16%;
  min-width: 60px;
  line-height: 14px;
}

.report-table-data {
  display: inline-block;
  font-family: futura-pt, sans-serif;
  color: #442c79;
  font-size: 14px;
}

.report-table-data.date {
  width: 70px;
}

.report-table-data.id {
  width: 100px;
}

.report-table-data.total {
  width: 70px;
  color: #000;
}

.link-block-7 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  color: #76b900;
  font-size: 16px;
  line-height: 24px;
  text-decoration: none;
}

.image-6 {
  margin-right: 10px;
}

.form-2 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 20px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.comment {
  width: 50%;
  border-style: solid;
  border-width: 0px 0px 0px 5px;
  border-color: #000 #000 #000 #442c79;
  font-family: 'Open Sans', sans-serif;
}

.comment.submitted {
  margin-top: auto;
}

.comment.submitted::-webkit-input-placeholder {
  color: #000;
}

.comment.submitted:-ms-input-placeholder {
  color: #000;
}

.comment.submitted::-ms-input-placeholder {
  color: #000;
}

.comment.submitted::placeholder {
  color: #000;
}

.paragraph-3 {
  color: #000;
  font-style: italic;
}

.contact-field-label {
  margin-top: 25px;
  color: #231f20;
  font-size: 16px;
  line-height: 26px;
}

.contact-field {
  height: 55px;
  border-style: solid;
  border-width: 2px;
  border-color: hsla(0, 0%, 77.3%, 0.3);
  -webkit-transition: border-color 500ms ease;
  transition: border-color 500ms ease;
  font-family: 'Open Sans', sans-serif;
  color: #231f20;
}

.contact-field:hover {
  border-color: #76b900;
}

.contact-field:focus {
  border-color: #76b900;
}

.contact-field::-webkit-input-placeholder {
  color: #000;
}

.contact-field:-ms-input-placeholder {
  color: #000;
}

.contact-field::-ms-input-placeholder {
  color: #000;
}

.contact-field::placeholder {
  color: #000;
}

.contact-form-wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 70%;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.form-item {
  width: 48%;
}

.contact-divider {
  width: 70%;
  height: 2px;
  margin-top: 30px;
  margin-bottom: 30px;
  background-color: hsla(0, 0%, 77.3%, 0.3);
}

.crud-action-icon-wrapper-2 {
  position: relative;
  margin-top: 3px;
  padding-right: 5px;
  padding-left: 5px;
  -webkit-transition-property: none;
  transition-property: none;
}

.crud-action-icon-wrapper-2:hover {
  opacity: 1;
}

.crud-action-icon-wrapper-2:active {
  -webkit-transform: translate(0px, 1px);
  -ms-transform: translate(0px, 1px);
  transform: translate(0px, 1px);
}

.table-wrapper-2 {
  display: block;
  border-top: 1px none #eee;
}

.image-7 {
  margin-left: 5px;
}

.doc-table-col {
  display: block;
  float: left;
  font-family: 'Open Sans', sans-serif;
  color: #000;
  font-size: 14px;
  line-height: 22px;
}

.doc-table-col.date-added {
  width: 20%;
  font-family: futura-pt-bold, sans-serif;
  color: #442c79;
  font-size: 14px;
}

.doc-table-col.file-name {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 60%;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.doc-table-col.id {
  width: 20%;
}

.doc-table-col.date {
  width: 70px;
}

.doc-table-col.name {
  width: 60%;
  font-family: futura-pt-bold, sans-serif;
  color: #442c79;
  font-size: 14px;
}

.doc-table-col.actions {
  position: relative;
  width: auto;
  padding-right: 0px;
  float: right;
  text-align: right;
}

.doc-table-col.survey-name {
  width: 60%;
}

.doc-table-col.date-added {
  width: 20%;
  color: #442c79;
  font-size: 14px;
}

.doc-table-col.name {
  width: 60%;
  color: #442c79;
  font-size: 14px;
}

.doc-table-col.name {
  width: 60%;
  font-family: futura-pt-bold, sans-serif;
  color: #442c79;
  font-size: 14px;
}

.doc-table-col.date-added {
  width: 20%;
  font-family: futura-pt-bold, sans-serif;
  color: #442c79;
  font-size: 14px;
}

.document-table-row {
  padding: 10px;
  border-top: 2px solid hsla(0, 0%, 77.3%, 0.3);
  border-bottom: 2px solid hsla(0, 0%, 77.3%, 0.3);
  background-color: #f5f3f7;
  font-family: futura-pt, sans-serif;
  font-weight: 700;
}

.dropdown-toggle {
  padding-top: 0px;
  padding-right: 0px;
  padding-bottom: 0px;
}

.account-menu {
  border-bottom: 1px solid #fff;
  background-color: #76b900;
  color: #fff;
  font-size: 14px;
  line-height: 24px;
  font-weight: 700;
}

.account-menu:hover {
  background-color: #83c01a;
}

.account-menu.last {
  border-bottom-style: none;
  border-bottom-width: 0px;
}

.menu-dropdown {
  border-radius: 5px;
}

.menu-dropdown.w--open {
  left: auto;
  top: 0%;
  right: 0%;
  bottom: 0%;
  width: 100px;
  margin-top: 35px;
  border-radius: 5px;
  box-shadow: 0 0 20px 0 hsla(0, 0%, 60.8%, 0.2);
}

.table-row-3 {
  display: block;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 10px;
  border-bottom: 0.5px none #c5c5c5;
}

.table-row-3:hover {
  background-color: #fafafa;
}

.table-row-3.school-row {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  height: 55px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-bottom: 2px solid hsla(0, 0%, 77.3%, 0.3);
  background-image: none;
  background-repeat: repeat;
}

.table-row-3.school-row:hover {
  background-image: url('../images/launch.svg');
  background-position: 98% 50%;
  background-repeat: no-repeat;
}

.table-row-3.table-header {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 45px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-top: 0px solid #000;
  border-bottom-width: 0px;
  background-color: #f5f3f7;
  color: #333;
  font-weight: 700;
}

.table-row-3.allocate-row {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 55px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.table-row-3.applications {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-top: 10px;
  padding-right: 10px;
  padding-bottom: 10px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-bottom: 2px solid hsla(0, 0%, 77.3%, 0.3);
}

.table-row-3.document {
  padding-top: 10px;
  padding-right: 10px;
  border-bottom: 2px solid hsla(0, 0%, 77.3%, 0.3);
}

.applications-table-row {
  padding: 10px;
  border-top: 2px solid hsla(0, 0%, 77.3%, 0.3);
  border-bottom: 2px solid hsla(0, 0%, 77.3%, 0.3);
  background-color: #f5f3f7;
}

.app-table-col {
  display: block;
  width: auto;
  float: left;
  font-family: 'Open Sans', sans-serif;
  color: #000;
  font-size: 14px;
  line-height: 22px;
}

.app-table-col.actions {
  position: relative;
  width: auto;
  padding-right: 0px;
  float: right;
  text-align: right;
}

.app-table-col.year {
  width: 40%;
}

.app-table-col.status {
  width: 40%;
}

.app-table-col.year {
  width: 25%;
}

.app-table-col.school-stat {
  width: 22%;
}

.app-table-col.sub-date {
  width: 12%;
}

.app-table-col.sub-by {
  width: 28%;
  padding-right: 5px;
}

.app-table-col.status {
  width: 20%;
}

.export-btn {
  padding: 10px 25px;
  border-radius: 20px;
  background-color: #442c79;
  font-family: futura-pt, sans-serif;
  font-size: 14px;
  line-height: 14px;
  font-weight: 700;
  text-transform: uppercase;
}

.account-info {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.account-links {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  height: 45px;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 10px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
  font-family: futura-pt, sans-serif;
  color: #4a4a4a;
  font-size: 16px;
  line-height: 26px;
  font-weight: 700;
  text-decoration: none;
}

.account-links:hover {
  background-color: #f5f3f7;
  color: #442c79;
}

.account-links.w--current {
  background-image: -webkit-gradient(linear, left top, left bottom, from(#f5f3f7), to(#f5f3f7));
  background-image: linear-gradient(180deg, #f5f3f7, #f5f3f7);
  color: #442c79;
}

.account-links.active {
  padding-right: 10px;
  background-color: #f5f3f7;
  color: #442c79;
}

.div-block-15 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 20%;
  margin-right: 50px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}

.apply-options {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 50px;
}

.div-block-16 {
  width: 2px;
  margin-right: 50px;
  margin-left: 50px;
  background-color: hsla(0, 0%, 77.3%, 0.3);
}

.div-block-17 {
  width: 80%;
}

.account-field-label {
  margin-top: 25px;
  font-family: futura-pt, sans-serif;
  color: #231f20;
  font-size: 16px;
  line-height: 26px;
  font-weight: 700;
}

.account-field {
  height: 55px;
  border-style: solid;
  border-width: 2px;
  border-color: hsla(0, 0%, 77.3%, 0.3);
  -webkit-transition: border-color 500ms ease;
  transition: border-color 500ms ease;
  font-family: 'Open Sans', sans-serif;
  color: #231f20;
  font-size: 16px;
  line-height: 26px;
}

.account-field:hover {
  border-color: #76b900;
}

.account-field:focus {
  border-color: #76b900;
}

.account-field::-webkit-input-placeholder {
  color: #000;
}

.account-field:-ms-input-placeholder {
  color: #000;
}

.account-field::-ms-input-placeholder {
  color: #000;
}

.account-field::placeholder {
  color: #000;
}

.form-3 {
  width: 40%;
}

.select-field {
  font-family: 'Open Sans', sans-serif;
  color: rgba(74, 74, 74, 0.5);
}

.program-management {
  font-family: futura-pt, sans-serif;
  color: #231f20;
  font-size: 25px;
  line-height: 35px;
  font-weight: 700;
}

.spacer-50 {
  height: 50px;
}

.section-topnav {
  position: fixed;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: auto;
  z-index: 1000;
  border-bottom: 2px solid hsla(0, 0%, 77.3%, 0.3);
  background-color: #fff;
}

.topnav-container {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 1300px;
  max-width: 90%;
  margin-right: auto;
  margin-left: auto;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.hfb {
  width: 125px;
}

.topnav-logos-flex {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.topnav-divider {
  width: 2px;
  height: 35px;
  margin-right: 25px;
  margin-left: 25px;
  background-color: hsla(0, 0%, 77.3%, 0.3);
}

.page-logo-link {
  text-decoration: none;
}

.program-management-page {
  font-family: futura-pt, sans-serif;
  color: #231f20;
  font-size: 16px;
  line-height: 16px;
  font-weight: 700;
}

.topnav-dropdowns-flex {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.dropdown-chevron {
  width: 20px;
  margin-left: 10px;
}

.topnav-dropdown {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 65px;
  padding-right: 0px;
  padding-left: 0px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.dropdown-label {
  font-family: futura-pt, sans-serif;
  color: #231f20;
  font-size: 16px;
  line-height: 16px;
  font-weight: 700;
}

.topnav-dropdown-link {
  padding-top: 15px;
  padding-bottom: 15px;
  border-bottom: 2px solid hsla(0, 0%, 100%, 0.2);
  -webkit-transition: background-color 500ms ease;
  transition: background-color 500ms ease;
  font-family: futura-pt, sans-serif;
  color: #fff;
  line-height: 14px;
  font-weight: 700;
}

.topnav-dropdown-link:hover {
  background-color: #94c938;
}

.topnav-dropdown-link.w--current {
  color: #fff;
}

.topnav-dropdown-link.last {
  border-bottom-width: 0px;
}

.topnav-dropdown-list {
  background-color: #76b900;
}

.section-dashboard {
  padding-top: 150px;
}

._1300-container {
  width: 1300px;
  max-width: 90%;
  margin-right: auto;
  margin-left: auto;
}

.program-listing {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 50px;
  padding-bottom: 50px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-bottom: 2px solid hsla(0, 0%, 77.3%, 0.3);
}

.program-listing.last {
  border-bottom-width: 0px;
}

.program-logo {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 25%;
  height: 160px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-style: solid;
  border-width: 2px;
  border-color: hsla(0, 0%, 77.3%, 0.3);
  border-radius: 5px;
  -webkit-transition: border-color 500ms ease;
  transition: border-color 500ms ease;
  text-decoration: none;
}

.program-logo:hover {
  border-color: #76b900;
}

.program-summary {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 75%;
  padding-left: 50px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.summary {
  padding-right: 50px;
}

.program-logo-text {
  font-family: futura-pt, sans-serif;
  color: #231f20;
  font-size: 25px;
  line-height: 25px;
  font-weight: 400;
}

.logo-bold {
  font-weight: 700;
}

.image-9 {
  width: 200px;
}

.image-10 {
  width: 200px;
}

.section-begin-application {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 100vh;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.section-begin-application.schoolmarket {
  height: auto;
  padding-top: 100px;
}

.half-width {
  width: 50%;
}

.start-flex {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.paragraph-link {
  border-bottom: 2px solid #76b900;
  -webkit-transition: color 500ms ease;
  transition: color 500ms ease;
  color: #231f20;
  text-decoration: none;
}

.paragraph-link:hover {
  color: #76b900;
}

.image-11 {
  width: 150px;
}

.image-12 {
  width: 150px;
}

.section-content {
  padding-top: 150px;
  padding-bottom: 150px;
}

.program-form {
  width: 1000px;
  max-width: 90%;
}

.select-field {
  height: 55px;
  border-style: solid;
  border-width: 2px;
  border-color: hsla(0, 0%, 77.3%, 0.3);
  background-color: #fff;
  -webkit-transition: border-color 500ms ease;
  transition: border-color 500ms ease;
  color: #231f20;
  font-size: 16px;
  line-height: 26px;
}

.select-field:hover {
  border-color: #76b900;
}

.select-field:focus {
  border-color: #76b900;
}

.checkbox-list {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}

.checkbox-stacked {
  display: inline-block;
  margin-right: 20px;
  margin-bottom: 10px;
  padding-left: 0px;
  font-family: 'Open Sans', sans-serif;
}

.field-textarea {
  min-height: 150px;
  font-family: 'Open Sans', sans-serif;
  color: #231f20;
  line-height: 24px;
}

.field-textarea.half {
  width: 49%;
}

.field-textarea.full {
  width: 100%;
}

.textarea-combo {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.program-form-block {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.next-step {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 25px;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}

.next-step.back {
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.progress-divider {
  width: 25px;
  height: 2px;
  background-color: hsla(0, 0%, 77.3%, 0.3);
}

.progress-divider.green {
  background-color: #76b900;
}

.text-block-6 {
  font-family: futura-pt, sans-serif;
  color: #231f20;
  font-weight: 700;
}

.upload-roster {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 25px;
  padding-top: 25px;
  padding-bottom: 25px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-top: 2px solid hsla(0, 0%, 77.3%, 0.3);
  border-bottom: 2px solid hsla(0, 0%, 77.3%, 0.3);
}

.divider {
  width: 100%;
  height: 2px;
  background-color: hsla(0, 0%, 77.3%, 0.3);
}

.divider.settings {
  margin-top: 50px;
  margin-bottom: 50px;
}

.italic-note {
  color: #c5c5c5;
}

.multi-select-box {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 55px;
  padding-right: 10px;
  padding-left: 10px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-style: solid;
  border-width: 2px;
  border-color: hsla(0, 0%, 77.3%, 0.3);
  background-color: #fff;
  -webkit-transition: border-color 500ms ease;
  transition: border-color 500ms ease;
}

.multi-select-box:hover {
  border-color: #76b900;
}

.subject {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-right: 5px;
  padding: 10px 15px;
  border-radius: 100px;
  background-color: rgba(118, 185, 0, 0.1);
}

.subject-text {
  font-family: 'Open Sans', sans-serif;
  color: #76b900;
  font-weight: 700;
}

.roster-table-headers {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: #f5f3f7;
}

.column {
  width: 20%;
}

.roster-table-header {
  margin-bottom: 0px;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 10px;
  font-size: 16px;
  line-height: 26px;
}

.roster-table {
  margin-top: 45px;
  margin-bottom: 50px;
}

.roster-content {
  padding-bottom: 50px;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  border-bottom: 2px solid hsla(0, 0%, 77.3%, 0.3);
}

.row {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.teachersaid-form {
  width: 1000px;
  max-width: 90%;
}

.line-item {
  margin-right: 20px;
  font-family: futura-pt, sans-serif;
  color: #442c79;
  font-weight: 700;
}

.login-flex {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 15px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.image-13 {
  width: 150px;
}

.backbackbuddy-form {
  width: 1000px;
  max-width: 90%;
  margin-right: auto;
  margin-left: auto;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.form-block-4 {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: end;
  -webkit-align-items: flex-end;
  -ms-flex-align: end;
  align-items: flex-end;
}

.bb-title {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.spacer-15 {
  height: 15px;
}

.section-sites {
  padding-top: 75px;
  background-color: #f5f3f7;
}

.subnav-flex {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.btn-container {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

.program-logo-text-2 {
  font-family: futura-pt, sans-serif;
  color: #231f20;
  font-size: 25px;
  line-height: 25px;
  font-weight: 400;
}

.program-management-page-2 {
  font-family: futura-pt, sans-serif;
  color: #231f20;
  font-size: 16px;
  line-height: 16px;
  font-weight: 700;
}

.topnav-dropdown-list-2 {
  background-color: #76b900;
}

.topnav-divider-2 {
  width: 2px;
  height: 35px;
  margin-right: 25px;
  margin-left: 25px;
  background-color: hsla(0, 0%, 77.3%, 0.3);
}

.dropdown-label-2 {
  font-family: futura-pt, sans-serif;
  color: #231f20;
  font-size: 16px;
  line-height: 16px;
  font-weight: 700;
}

.topnav-dropdown-link-2 {
  padding-top: 15px;
  padding-bottom: 15px;
  border-bottom: 2px solid hsla(0, 0%, 100%, 0.2);
  -webkit-transition: background-color 500ms ease;
  transition: background-color 500ms ease;
  font-family: futura-pt, sans-serif;
  color: #fff;
  line-height: 14px;
  font-weight: 700;
}

.topnav-dropdown-link-2:hover {
  background-color: #94c938;
}

.topnav-dropdown-link-2.w--current {
  color: #fff;
}

.topnav-dropdown-link-2.last {
  border-bottom-width: 0px;
}

.section-subnav {
  padding-top: 70px;
  padding-bottom: 15px;
  background-color: #f5f3f7;
}

.subnav-flex-2 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 10px;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.admin-dash-tabs {
  text-align: center;
}

.dashboard-link-2 {
  margin-right: 30px;
  border-bottom: 2px solid transparent;
  -webkit-transition: border-color 500ms ease;
  transition: border-color 500ms ease;
  font-family: futura-pt, sans-serif;
  color: #231f20;
  font-size: 14px;
  font-weight: 700;
  text-decoration: none;
}

.dashboard-link-2:hover {
  border-bottom-color: #76b900;
}

.dashboard-link-2.w--current {
  border-bottom-color: #76b900;
}

.dashboard-link-2.last {
  margin-right: 0px;
}

.table-col-2 {
  display: block;
  overflow: hidden;
  width: 20%;
  min-width: 100px;
  padding-right: 10px;
  float: left;
  font-family: 'Open Sans', sans-serif;
  color: #4a4a4a;
  font-size: 13px;
  line-height: 16px;
}

.table-col-2.id {
  width: 9%;
  min-width: auto;
  font-family: futura-pt, sans-serif;
  color: #442c79;
  font-size: 14px;
}

.table-col-2.county-item {
  width: 9%;
  min-width: auto;
  color: #231f20;
}

.table-col-2.school {
  width: 20%;
  min-width: auto;
  font-family: futura-pt, sans-serif;
  color: #442c79;
  font-size: 14px;
}

.table-col-2.allocation-item {
  width: 9%;
  min-width: auto;
  color: #231f20;
}

.table-col-2.county {
  width: 9%;
  min-width: auto;
  font-family: futura-pt, sans-serif;
  color: #442c79;
  font-size: 14px;
}

.table-col-2.foodsafety-item {
  width: 9%;
  min-width: auto;
  color: #231f20;
}

.table-col-2.zip-item {
  width: 9%;
  min-width: auto;
  color: #231f20;
}

.table-col-2.district {
  width: 10%;
  min-width: auto;
  font-family: futura-pt, sans-serif;
  color: #442c79;
  font-size: 14px;
}

.table-col-2.status-item {
  width: 9%;
  min-width: auto;
  color: #231f20;
}

.table-col-2.status {
  width: 9%;
  min-width: auto;
  font-family: futura-pt, sans-serif;
  color: #442c79;
  font-size: 14px;
}

.table-col-2.zip {
  width: 9%;
  min-width: auto;
  font-family: futura-pt, sans-serif;
  color: #442c79;
  font-size: 14px;
}

.table-col-2.new-item {
  width: 3%;
  min-width: auto;
  color: #231f20;
}

.table-col-2.allocation {
  width: 9%;
  min-width: auto;
  font-family: futura-pt, sans-serif;
  color: #442c79;
  font-size: 14px;
}

.table-col-2.level {
  width: 8%;
  min-width: auto;
  font-family: futura-pt, sans-serif;
  color: #442c79;
  font-size: 14px;
  font-weight: 700;
}

.table-col-2.new {
  width: 3%;
  min-width: auto;
  font-family: futura-pt, sans-serif;
  color: #442c79;
  font-size: 14px;
}

.table-col-2.id-item {
  width: 9%;
  min-width: auto;
  color: #231f20;
}

.table-col-2.district-item {
  width: 10%;
  min-width: auto;
  color: #231f20;
}

.table-col-2.level-item {
  width: 8%;
  min-width: auto;
  color: #231f20;
}

.table-col-2.school-item {
  width: 20%;
  min-width: auto;
  color: #231f20;
}

.table-col-2.foodsafety {
  width: 9%;
  min-width: auto;
  font-family: futura-pt, sans-serif;
  color: #442c79;
  font-size: 14px;
  font-weight: 700;
}

.table-col-2.allocate-cell {
  width: 30%;
}

.table-col-2.allocate-table {
  width: 30%;
  color: #442c79;
}

.table-col-2.table-header {
  font-family: futura-pt-bold, sans-serif;
  color: #442c79;
  font-size: 14px;
}

.table-col-2.fnln-cell {
  width: 15%;
}

.table-col-2.fnln-users {
  width: 15%;
  color: #442c79;
}

.table-col-2.actions {
  position: relative;
  width: auto;
  min-width: 50px;
  padding-right: 0px;
  float: right;
  text-align: right;
}

.link-block-8 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-bottom: 2px solid #76b900;
  color: #4a4a4a;
  text-decoration: none;
}

.search-bar {
  height: 55px;
  border-style: solid;
  border-width: 2px;
  border-color: hsla(0, 0%, 77.3%, 0.3);
  background-image: url('../images/magnifying-glass.svg');
  background-position: 99% 50%;
  background-repeat: no-repeat;
  -webkit-transition: border-color 500ms ease;
  transition: border-color 500ms ease;
  font-family: 'Open Sans', sans-serif;
  color: #231f20;
}

.search-bar:hover {
  border-color: #76b900;
}

.search-bar:focus {
  border-color: #76b900;
}

.search-bar::-webkit-input-placeholder {
  color: #c5c5c5;
}

.search-bar:-ms-input-placeholder {
  color: #c5c5c5;
}

.search-bar::-ms-input-placeholder {
  color: #c5c5c5;
}

.search-bar::placeholder {
  color: #c5c5c5;
}

.search-bar.user-search {
  margin-right: 5px;
}

.link-block-9 {
  width: 100%;
  text-decoration: none;
}

.filters {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.pagination-div {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 180px;
  margin: 50px auto 15px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  font-family: futura-pt, sans-serif;
  color: #f1f1f1;
  font-weight: 700;
  text-align: center;
}

.text-block-7 {
  font-family: 'Open Sans', sans-serif;
  color: #4a4a4a;
  line-height: 24px;
}

.page-link {
  color: #c5c5c5;
  text-decoration: none;
}

.page-link.active-page {
  color: #442c79;
}

.table-data-4 {
  display: inline-block;
  font-family: futura-pt, sans-serif;
  font-size: 14px;
  font-weight: 700;
}

.table-data-4.year {
  font-family: futura-pt-bold, sans-serif;
  color: #442c79;
  font-weight: 700;
}

.table-data-4.status {
  font-family: futura-pt-bold, sans-serif;
  color: #442c79;
  font-weight: 700;
}

.table-wrapper-3 {
  display: block;
  margin-top: 20px;
  margin-bottom: 30px;
  border-top: 0px solid #eee;
}

.table-wrapper-3.applications {
  width: 80%;
  margin-top: 0px;
  border-top-style: none;
  border-top-width: 0px;
}

.export-btn-2 {
  border-radius: 20px;
  background-color: #442c79;
  font-family: futura-pt-bold, sans-serif;
  font-size: 14px;
  line-height: 14px;
  font-weight: 700;
  text-transform: uppercase;
}

.filter-select {
  display: inline-block;
  height: 55px;
  margin-right: 5px;
  margin-bottom: 0px;
  border-style: solid;
  border-width: 2px;
  border-color: hsla(0, 0%, 77.3%, 0.3);
  background-color: #fff;
  -webkit-transition: border-color 500ms ease;
  transition: border-color 500ms ease;
  font-family: 'Open Sans', sans-serif;
  color: #231f20;
}

.filter-select:hover {
  border-color: #76b900;
}

.filter-select.last-filter {
  margin-right: 0px;
}

.filter-select.user-filter {
  width: 30%;
}

.dashboard-link-3 {
  margin-right: 30px;
  border-bottom: 2px solid transparent;
  -webkit-transition: border-color 500ms ease;
  transition: border-color 500ms ease;
  color: #231f20;
  font-size: 14px;
  font-weight: 700;
  text-decoration: none;
}

.dashboard-link-3:hover {
  border-bottom-color: #76b900;
}

.dashboard-link-3.w--current {
  border-bottom-color: #76b900;
}

.dashboard-link-3.active {
  border-bottom: 2px solid #76b900;
}

.dashboard-link-3.last {
  margin-right: 0px;
}

.text-block-8 {
  font-family: futura-pt, sans-serif;
}

.text-block-9 {
  font-family: futura-pt, sans-serif;
}

.text-block-10 {
  font-family: futura-pt, sans-serif;
}

.profile-intro {
  margin-bottom: 50px;
}

.back-btn {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  font-family: futura-pt, sans-serif;
  color: #76b900;
  font-weight: 700;
  text-decoration: none;
  text-transform: uppercase;
}

.div-block-19 {
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.divider-2 {
  width: 100%;
  height: 2px;
  background-color: hsla(0, 0%, 77.3%, 0.3);
}

.divider-2.settings {
  margin-top: 50px;
  margin-bottom: 50px;
}

.field-label-2 {
  font-family: futura-pt, sans-serif;
  color: #231f20;
  font-size: 16px;
  line-height: 26px;
}

.field-label-2.inline-date {
  margin-right: 10px;
}

.field-combo-wrapper-2 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 15px;
  margin-bottom: 15px;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.field-combo-wrapper-2.radio-wrapper {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.values-text {
  display: inline-block;
  font-family: 'Open Sans', sans-serif;
  font-size: 14px;
  line-height: 16px;
  font-style: italic;
  font-weight: 400;
}

.detail-tabs {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 20%;
  margin-right: 50px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
}

.checkbox-inline-2 {
  display: inline-block;
  margin-right: 20px;
  padding-left: 0px;
  font-family: 'Open Sans', sans-serif;
  font-size: 14px;
  line-height: 20px;
}

.last-edit {
  margin-bottom: 40px;
  font-family: 'Open Sans', sans-serif;
  font-size: 14px;
  line-height: 16px;
  font-style: italic;
  font-weight: 400;
}

.field-combo-2 {
  display: inline-block;
  width: 50%;
  padding-right: 10px;
  float: left;
}

.btn-padding {
  margin-top: 30px;
}

.checkbox-label-4 {
  padding-left: 30px;
  background-image: url('../images/empty-radio.svg');
  background-position: 0% 50%;
  background-size: 20px 20px;
  background-repeat: no-repeat;
}

.radio-label-2 {
  padding-left: 30px;
  background-image: url('../images/empty-radio.svg');
  background-position: 0% 50%;
  background-size: 20px 20px;
  background-repeat: no-repeat;
  color: #231f20;
  font-size: 16px;
  line-height: 26px;
}

.text-block-11 {
  font-family: futura-pt, sans-serif;
}

.image-14 {
  margin-right: 7px;
}

.back-btn-2 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 160px;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  font-family: futura-pt, sans-serif;
  color: #76b900;
  font-weight: 700;
  text-decoration: none;
  text-transform: uppercase;
}

.contact-block {
  margin-bottom: 20px;
  padding-bottom: 20px;
}

.contact-block.last-contact {
  border-bottom-style: none;
}

.image-15 {
  margin-right: 7px;
}

.select-field-2 {
  height: 55px;
  border-style: solid;
  border-width: 2px;
  border-color: hsla(0, 0%, 77.3%, 0.3);
  background-color: #fff;
  font-family: 'Open Sans', sans-serif;
  color: #231f20;
}

.select-field-2:hover {
  border-color: #76b900;
}

.div-block-20 {
  display: block;
  clear: left;
}

.program-link {
  margin-right: 25px;
  margin-left: 0px;
  padding-right: 0px;
  padding-left: 0px;
  border-bottom: 2px solid transparent;
  background-color: transparent;
  -webkit-transition: border-color 500ms ease;
  transition: border-color 500ms ease;
  font-family: futura-pt, sans-serif;
  color: #76b900;
  font-size: 16px;
  line-height: 20px;
  font-weight: 700;
}

.program-link:hover {
  border-bottom-color: #76b900;
}

.totals-divider-2 {
  height: 2px;
  margin-top: 15px;
  margin-bottom: 15px;
  background-color: #c5c5c5;
}

.report-table-row-2 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 10px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-bottom: 2px solid hsla(0, 0%, 77.3%, 0.3);
}

.report-table-row-2:hover {
  background-color: #fafafa;
}

.report-table-row-2.table-header {
  border-bottom-style: none;
  background-color: #fff;
  color: #333;
  font-weight: 700;
}

.date-div-2 {
  margin-bottom: 10px;
  padding: 10px 15px 25px;
  border-style: solid;
  border-width: 2px;
  border-color: hsla(0, 0%, 77.3%, 0.3);
  border-radius: 5px;
}

.date-div-2.admin-report {
  padding-top: 25px;
}

.date-div-2.active-details {
  padding-bottom: 0px;
  background-color: #f5f3f7;
}

.read-link {
  color: #442c79;
}

.add-student-link {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-left: 10px;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  font-family: futura-pt, sans-serif;
  color: #76b900;
  font-size: 16px;
  line-height: 24px;
  font-weight: 700;
  text-decoration: none;
}

.report-table-data-2 {
  display: inline-block;
  width: auto;
  font-family: futura-pt, sans-serif;
  color: #442c79;
  font-size: 14px;
}

.report-table-data-2.total {
  width: 70px;
  color: #000;
}

.text-block-12 {
  font-family: 'Open Sans', sans-serif;
  color: #4a4a4a;
}

.student-distribution-report-2 {
  margin-top: 25px;
  padding: 20px;
  border-top: 2px solid hsla(0, 0%, 77.3%, 0.3);
  border-bottom: 2px solid hsla(0, 0%, 77.3%, 0.3);
  background-color: #fff;
}

.comment-2 {
  width: 50%;
  border-style: solid;
  border-width: 0px 0px 0px 5px;
  border-color: #000 #000 #000 #442c79;
  font-family: 'Open Sans', sans-serif;
}

.comment-2.full-width {
  width: 100%;
}

.link-block-10 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  font-family: futura-pt, sans-serif;
  color: #442c79;
  font-size: 14px;
  font-weight: 700;
  text-decoration: none;
}

.div-block-21 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 20px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.vertical-divider-2 {
  width: 2px;
  height: 20px;
  margin-right: 10px;
  margin-left: 10px;
  background-color: hsla(0, 0%, 77.3%, 0.3);
}

.text-span-2 {
  color: #442c79;
  font-weight: 700;
}

.report-text {
  font-family: 'Open Sans', sans-serif;
  font-size: 14px;
  line-height: 16px;
  font-style: italic;
  font-weight: 400;
}

.distribution-stats-2 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.applications-table-row-2 {
  padding: 10px;
  background-color: #f5f3f7;
}

.select-dropdown {
  width: auto;
  margin-bottom: 0px;
  border-style: solid;
  border-width: 2px;
  border-color: hsla(0, 0%, 77.3%, 0.3);
  background-color: #fff;
  font-family: 'Open Sans', sans-serif;
}

.form-block-6 {
  margin-bottom: 0px;
}

.text-block-13 {
  display: inline-block;
}

.div-block-22 {
  margin-bottom: 20px;
  text-align: right;
}

.add-user-link {
  display: inline-block;
  width: auto;
  margin-right: 20px;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  font-family: futura-pt, sans-serif;
  color: #76b900;
  font-size: 16px;
  line-height: 24px;
  font-weight: 700;
  text-decoration: none;
}

.delete-link {
  margin-left: 20px;
  padding-right: 0px;
  padding-left: 0px;
  border-bottom: 2px solid transparent;
  background-color: transparent;
  -webkit-transition: border-color 500ms ease;
  transition: border-color 500ms ease;
  font-family: futura-pt, sans-serif;
  color: #db0c41;
  font-size: 16px;
  line-height: 20px;
  font-weight: 700;
}

.delete-link:hover {
  border-bottom-color: #db0c41;
}

.remove-school {
  position: relative;
  bottom: 10px;
  font-family: 'Open Sans', sans-serif;
  color: #db0c41;
  font-size: 12px;
  line-height: 16px;
}

.checkbox-2 {
  margin-top: 8px;
}

.checkbox-field-3 {
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.top-border {
  margin-top: 20px;
  margin-bottom: 40px;
  padding-top: 20px;
}

.text-block-14 {
  font-family: 'Open Sans', sans-serif;
  color: #231f20;
}

.edit-link {
  color: #442c79;
}

.banner-textarea {
  min-height: 150px;
  border-style: solid;
  border-width: 2px;
  border-color: hsla(0, 0%, 77.3%, 0.3);
  -webkit-transition: border-color 500ms ease;
  transition: border-color 500ms ease;
  font-family: 'Open Sans', sans-serif;
  color: #231f20;
}

.banner-textarea:hover {
  border-color: #76b900;
}

.banner-textarea::-webkit-input-placeholder {
  color: #c5c5c5;
}

.banner-textarea:-ms-input-placeholder {
  color: #c5c5c5;
}

.banner-textarea::-ms-input-placeholder {
  color: #c5c5c5;
}

.banner-textarea::placeholder {
  color: #c5c5c5;
}

.banner-text-form {
  width: 400px;
}

.document-table-row-3 {
  padding: 10px;
  background-color: #f5f3f7;
  font-family: futura-pt-bold, sans-serif;
  font-weight: 700;
}

.image-16 {
  margin-right: 7px;
}

.image-17 {
  margin-right: 7px;
}

.image-18 {
  margin-right: 7px;
}

.image-19 {
  margin-right: 7px;
}

.image-20 {
  margin-right: 7px;
}

.section-page {
  padding-top: 50px;
}

.site-info-line {
  display: inline-block;
  width: 50%;
}

.site-info {
  display: inline-block;
  margin-right: 5px;
  font-family: futura-pt, sans-serif;
  color: #231f20;
  font-size: 16px;
  line-height: 26px;
  font-weight: 700;
}

.site-info-input {
  display: inline-block;
  margin-right: 10px;
  font-family: 'Open Sans', sans-serif;
  color: #231f20;
  font-size: 16px;
  line-height: 26px;
}

.div-block-23 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}

.rich-text-block {
  margin-top: 25px;
  font-family: 'Open Sans', sans-serif;
}

html.w-mod-js *[data-ix="table-sort-icon-initial-state"] {
  opacity: 0;
}

@media screen and (max-width: 991px) {
  .registration-flex-wrapper {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .logo-sidebar {
    width: auto;
    height: 120px;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
  }
  .login-container {
    width: auto;
  }
  .purple-headline.half-width {
    max-width: 90%;
  }
  .crud-action-icon-wrapper {
    min-width: 25px;
    margin-right: 0px;
    margin-bottom: 5px;
    margin-left: 0px;
  }
  .table-col.actions {
    position: relative;
    top: 0px;
    right: 0px;
    width: 23%;
    text-align: right;
  }
  .table-row {
    display: block;
  }
  .date-from.date-stack {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
  }
  .date-range {
    margin-bottom: 20px;
  }
  .export-reports-div {
    width: 100%;
  }
  .date-export {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .date-export.bp-distribute {
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
  }
  .report-table-row {
    display: block;
  }
  .report-table-col.actions {
    position: relative;
    top: 0px;
    right: 0px;
    width: 23%;
    text-align: right;
  }
  .report-table-col.cell-1 {
    width: 100%;
  }
  .report-table-col.cell-2 {
    width: 100%;
  }
  .crud-action-icon-wrapper-2 {
    min-width: 25px;
    margin-right: 0px;
    margin-bottom: 5px;
    margin-left: 0px;
  }
  .doc-table-col.actions {
    position: relative;
    top: 0px;
    right: 0px;
    width: 23%;
    text-align: right;
  }
  .table-row-3 {
    display: block;
  }
  .app-table-col.actions {
    position: relative;
    top: 0px;
    right: 0px;
    width: 23%;
    text-align: right;
  }
  .app-table-col.actions.tablet-wrap {
    position: static;
    margin-top: 20px;
    float: right;
    clear: left;
  }
  .app-table-col.year {
    padding-right: 5px;
  }
  .app-table-col.sub-date {
    width: 20%;
  }
  .app-table-col.sub-by {
    width: 20%;
  }
  .table-col-2.allocate-cell {
    width: 30%;
  }
  .table-col-2.allocate-table {
    width: 30%;
  }
  .table-col-2.fnln-cell {
    width: 18%;
  }
  .table-col-2.fnln-users {
    width: 18%;
  }
  .table-col-2.actions {
    position: relative;
    top: 0px;
    right: 0px;
    width: 23%;
    text-align: right;
  }
  .filters {
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
  .export-btn-2 {
    float: none;
  }
  .filter-select {
    width: 20%;
    margin-bottom: 5px;
  }
  .field-combo-wrapper-2 {
    display: block;
  }
  .field-combo-2 {
    width: 50%;
    max-width: none;
    margin-right: 0px;
    padding-right: 10px;
  }
  .report-table-row-2 {
    display: block;
  }
  .add-student-link {
    margin-top: 10px;
    margin-left: 0px;
  }
  .div-block-21 {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
  }
  .add-user-link {
    margin-top: 10px;
    margin-left: 0px;
  }
  .site-info-input {
    display: block;
  }
}

@media screen and (max-width: 767px) {
  .purple-headline.half-width {
    font-size: 28px;
    line-height: 32px;
  }
  .reg-wrapper-div {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .progress-bar {
    width: 250px;
    margin-top: 20px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .bubble-div {
    margin-top: 15px;
  }
  .radio-inline {
    display: block;
  }
  .checkbox-inline {
    display: block;
  }
  .checkbox-label {
    padding-top: 4px;
    padding-bottom: 4px;
    background-size: auto;
  }
  .crud-action-icon-wrapper {
    min-width: 15px;
    margin-right: 10px;
    margin-left: 0px;
    padding-left: 0px;
  }
  .table-col {
    min-width: 100%;
  }
  .table-col.table-header {
    padding-left: 10px;
  }
  .table-col.actions {
    margin-top: 10px;
    text-align: left;
  }
  .table-row {
    padding-left: 10px;
    border-bottom: 1px solid #dfdfdf;
  }
  .table-row.table-header {
    display: none;
  }
  .user-settings {
    margin-top: 20px;
  }
  .date-from.date-stack {
    margin-bottom: 15px;
  }
  .date-range.date-pad {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .dashboard-nav {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
  }
  .report-table-row {
    padding-left: 10px;
    border-bottom: 1px solid #dfdfdf;
  }
  .report-table-row.table-header {
    display: none;
  }
  .report-table-col {
    min-width: 100%;
  }
  .report-table-col.table-header {
    padding-left: 10px;
  }
  .report-table-col.actions {
    margin-top: 10px;
    text-align: left;
  }
  .crud-action-icon-wrapper-2 {
    min-width: 15px;
    margin-right: 10px;
    margin-left: 0px;
    padding-left: 0px;
  }
  .doc-table-col {
    min-width: 100%;
  }
  .doc-table-col.actions {
    margin-top: 10px;
    text-align: left;
  }
  .table-row-3 {
    padding-left: 10px;
    border-bottom: 1px solid #dfdfdf;
  }
  .table-row-3.table-header {
    display: none;
  }
  .app-table-col {
    min-width: 100%;
  }
  .app-table-col.actions {
    margin-top: 10px;
    text-align: left;
  }
  .checkbox-stacked {
    display: block;
  }
  .table-col-2 {
    min-width: 100%;
  }
  .table-col-2.table-header {
    padding-left: 10px;
  }
  .table-col-2.actions {
    margin-top: 10px;
    text-align: left;
  }
  .checkbox-inline-2 {
    display: block;
  }
  .checkbox-label-4 {
    padding-top: 4px;
    padding-bottom: 4px;
    background-size: auto;
  }
  .report-table-row-2 {
    padding-left: 10px;
    border-bottom: 1px solid #dfdfdf;
  }
  .report-table-row-2.table-header {
    display: none;
  }
  .site-info-line {
    width: 100%;
  }
  .site-info-input {
    display: inline-block;
  }
}

@media screen and (max-width: 479px) {
  .logo-sidebar {
    height: 100px;
  }
  .tabs-menu {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    margin-top: 70px;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
  }
  .login-tab-link {
    margin-right: 20px;
    margin-left: 0px;
  }
  .purple-headline.half-width {
    width: auto;
  }
  .field-combo {
    margin-right: 0px;
  }
  .field-combo-wrapper {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
  }
  .progress-bar {
    width: auto;
  }
  .form-save {
    display: inline-block;
    margin-top: 20px;
    margin-left: 0px;
  }
  .agreement-div {
    padding: 15px;
  }
  .table-wrapper {
    font-family: Lato, sans-serif;
  }
  .table-col {
    min-width: 100%;
  }
  .table-col.actions {
    margin-top: 10px;
    margin-bottom: 10px;
    text-align: right;
  }
  .table-row {
    overflow: visible;
  }
  .report-table {
    font-family: Lato, sans-serif;
  }
  .report-table-row {
    overflow: visible;
  }
  .report-table-col {
    min-width: 100%;
  }
  .report-table-col.actions {
    margin-top: 10px;
    margin-bottom: 10px;
    text-align: right;
  }
  .table-wrapper-2 {
    font-family: Lato, sans-serif;
  }
  .doc-table-col {
    min-width: 100%;
  }
  .doc-table-col.actions {
    margin-top: 10px;
    margin-bottom: 10px;
    text-align: right;
  }
  .table-row-3 {
    overflow: visible;
  }
  .app-table-col {
    min-width: 100%;
  }
  .app-table-col.actions {
    margin-top: 10px;
    margin-bottom: 10px;
    text-align: right;
  }
  .table-col-2 {
    min-width: 100%;
  }
  .table-col-2.actions {
    margin-top: 10px;
    margin-bottom: 10px;
    text-align: right;
  }
  .table-wrapper-3 {
    font-family: Lato, sans-serif;
  }
  .field-combo-wrapper-2 {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
  }
  .field-combo-2 {
    margin-right: 0px;
  }
  .program-link {
    display: inline-block;
    margin-top: 20px;
    margin-left: 0px;
  }
  .report-table-row-2 {
    overflow: visible;
  }
  .delete-link {
    display: inline-block;
    margin-top: 20px;
    margin-left: 0px;
  }
}

